import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/HomePage.vue";
// import App from "../App.vue";
import ThankYou from "../views/ThankYou.vue";
// import { createApp } from "vue";
const routes = [
    {
        path: "/:id",
        name: "Home",
        component: Home,
    },
    {
        path: "/thank-you",
        name: "ThankYou",
        component: ThankYou,
    },
];
// process.env.BASE_URL
const router = createRouter({
    history: createWebHistory(),
    routes,
});
// createApp(App).use(router).mount("#app");
export default router;
