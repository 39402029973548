import { createApp } from "vue";
// import App from './views/Home.vue'
import router from "./router";
import App from "./App.vue";
import store from "../store";
// Load Google Maps API globally
const script = document.createElement("script");
script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA3-6MGuTtN24nw6KOm9KSPONVbeENsIZc&libraries=places,marker`;
script.async = true;
document.head.appendChild(script);
const app = createApp(App);
app.use(store);
app.use(router);
app.mount("#app");
